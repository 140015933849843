import moment from "moment"
import React from "react"
import { useState } from "react"

const initialAuthorsLimit = 3

export default function ArticleHeader(props) {
  const { article } = props

  const [authorsExpanded, setAuthorsExpanded] = useState()

  const authors =
    article.authors.length > initialAuthorsLimit && !authorsExpanded
      ? article.authors
          .filter(e => e)
          .map(a => a.name)
          .slice(0, initialAuthorsLimit)
          .join(", ") + "..."
      : article.authors
          .filter(e => e)
          .map(a => a.name)
          .join(", ")

  return (
    <div className="flex flex-column flex-row-ns items-start items-center-ns justify-between w-100 mb5">
      <div className="flex flex-column justify-between mr2">
        <div className="flex flex-column flex-row-ns items-start items-center-ns">
          <div className="white f4 font-martian">{article.category.title}</div>
          <div className="white f4 white mh3 dn db-ns">|</div>
          <div className="font-martian">
            Published: {moment(article.created_at).format("DD MMMM Y")}
          </div>
        </div>
        <h3 className="mv4 white">{article.title}</h3>
        <div>
          {authors}{" "}
          {article.authors.length > initialAuthorsLimit && (
            <button
              onClick={() => setAuthorsExpanded(!authorsExpanded)}
              className="ml2 uk-button button-white-rounded-sm"
            >
              {authorsExpanded ? "Hide authors" : "Show authors"}
            </button>
          )}
        </div>
      </div>
      <a
        href={article.download_url}
        download={article.title + ".pdf"}
        className="mt4 mt0-ns"
      >
        <button
          className="uk-button button-white-rounded"
          style={{ width: "200px" }}
        >
          Download PDF
        </button>
      </a>
    </div>
  )
}
