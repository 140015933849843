import React from "react"
import Layout from "../layout"
import SEO from "../seo"
import ArticleHeader from "../../components/publications/publication-article/article-header"
import { useEffect } from "react"
import ArticleBody from "./publication-article/article-body"

export default function PublicationArticle(props) {
  const { data: article, lang } = props.pageContext

  useEffect(() => {}, [])

  return (
    <Layout lang={lang}>
      <SEO title={article.title} />
      <main>
        <section className="uk-container" style={{ paddingTop: "250px" }}>
          <ArticleHeader article={article} />
          <ArticleBody article={article} />
        </section>
      </main>
    </Layout>
  )
}
