import React from "react"
import { useEffect } from "react"
import ReactMarkdown from "react-markdown"
import "./article-style.css"

export default function ArticleBody(props) {
  const { content_type, content } = props.article

  return (
    <div className="article-body">
      {content_type === "html" ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <ReactMarkdown>{content}</ReactMarkdown>
      )}
    </div>
  )
}
